@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');

*,
::after,
::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'ABeeZee', sans-serif;
}

html {
  background-color: #ffffff;
  color: black;
}

.div-style {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-container {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
}

.header {
  padding: 1rem;
  top: 0;
  width: 100%;
  z-index: 8;
  margin-bottom: 3rem;
}

.tagline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.blurb {
  padding: 1rem;
  margin: 5rem 0 1rem 0;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.home-container {
  margin-bottom: 10rem;
}

.masonry-width-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-top: 5rem;
  width: 100%;
  height: 90vh;
}

.masonry-width {
  height: 450px;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  position: relative;
}

.carousel-img {
  position: absolute;
  object-fit: cover;
  height: 500px;
  border-radius: 10px;
  width: 100%;
  display: none;
}

.tree-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: 2rem;
  width: 100%;
  height: 100%;
  margin: 3rem 0 10rem 0;
  gap: 1rem;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  width: 100%;
  height: 100%;
  margin: 3rem 0;
  gap: 1rem;
}

.lg-click {
  display: block;
  margin: 15px;
  font-size: 0.8rem;
}

.sm-click {
  display: none;
  margin: 15px;
  font-size: 0.6rem;
}

/* ===== Desktop view ===== */
@media screen and (min-width: 601px) {
  .title-name {
    font-size: 2.2rem;
  }
  .tagline {
    font-size: 1.35rem;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  .app-container {
    max-width: 768px;
  }

  .tree-container div {
    max-width: 500px;
  }

  .masonry-width {
    width: 60vw;
  }

  .carousel-container {
    width: 80%;
  }
}

/* =====  Mobile view ===== */
@media screen and (max-width: 600px) {
  html,
  body {
    overflow-x: hidden;
  }

  .title-name {
    font-size: 1.5rem;
  }
  .tagline {
    font-size: 0.9rem;
  }

  .app-container {
    width: 100%;
  }
  .splash-screen {
    font-size: 1rem;
    padding: 0.2rem;
  }

  .tree-container div {
    max-width: 375px;
  }

  .masonry-width {
    width: 100%;
  }
  .carousel-container {
    width: 100%;
  }

  .lg-click {
    display: none;
  }

  .sm-click {
    display: block;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  display: block;
}

.fade-out {
  -webkit-animation: fade-out 750ms ease-out both;
  animation: fade-out 750ms ease-out both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
